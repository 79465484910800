<template>
  <div class="column-center">
    <div style="position: relative">
      <img :src="icons.group_banner35" class="full-width" />
      <video
        :src="icons.group_video_artist35"
        class="group-content-width group-video"
        style="width: 70vw"
        controls
      ></video>
    </div>

    <!-- <span class="group-title-white group-content-width">艺术家详情</span> -->

    <div class="column group-content-width" style="margin: 55px 0">
      <span class="group-title">马尔戈扎塔·帕斯科 作品</span>
      <img :src="icons.group_arrow" width="66" style="margin-top: 60px" />

      <div class="row" style="margin-top: 50px; justify-content: center">
        <img
          :src="icons.group_artist35p1"
          width="436"
          style="margin-right: 51px"
        />
        <img :src="icons.group_artist35p2" width="330" />
      </div>
    </div>

    <!-- <span class="group-page-english" style="margin-top: 80px">3-5ans</span>
    <span class="group-page-title"> 3-5岁组联创艺术家 </span>
    <img
      :src="icons.intro_triangle2"
      height="15"
      style="margin-top: 10px; margin-bottom: 55px"
    /> -->

    <div class="full-width column-center group-art-container">
      <div class="row" style="margin-bottom: 35px">
        <img :src="icons.group_triangle_left" width="15" />
        <span class="group-art-title">马尔戈扎塔·帕斯科 </span>
        <img :src="icons.group_triangle_right" width="15" />
      </div>

      <img
        :src="icons.group_artist35"
        width="450"
        style="margin-bottom: 68px"
      />

      <span class="group-art-desc group-content-width"
        >生活在诺曼底的印象派画家与雕刻家，罗马奖（梅蒂奇别墅）获得者，曾多次于2016-2017在中国居住并展出。同时，在法国、比利时、德国、希腊、波兰以及黎巴嫩等国举办了许多展览。其部分作品现展览于法国当代艺术基金会、毕加索博物馆、
        法国国家图书馆、波兰托伦大学、萨拉热窝国家美术馆、卢森堡杜德朗日市政府……</span
      >
    </div>
  </div>
</template>

<script>
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
};
</script>

<style scoped>
.group-content-width {
  width: 800px;
}
.group-title-white {
  font-family: PingFangSC-Medium;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: -1.23px;
  margin-top: -85px;
}

.group-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
}

.group-page-english {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.group-page-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}

.group-art-container {
  padding-top: 80px;
  padding-bottom: 150px;
  background-color: #ff3000;
}
.group-art-title {
  font-family: PingFangSC-Semibold;
  font-size: 48px;
  color: #ffffff;
  letter-spacing: -1.65px;
  margin: 0 35px;
}

.group-art-desc {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: -0.82px;
  text-align: center;
}
</style>